@charset "utf-8";
@import "mixin";

/* ============================================================
 init
============================================================ */
body {
  color: $dc;
  font-family: YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-size: 14px;
  font-weight: $regular;
  line-height:1.5;
}
#home,
#world,
#agents {
  font-family: YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
}

#wrapper {
  margin:0 auto;
  @media screen and (min-width: $breakpoint + 1px) {
    min-width: 960px;
  }
}
.inner {
  @media screen and (min-width: $breakpoint + 1px) {
    width: 960px;
    margin: auto;
  }
  @media screen and (max-width: $breakpoint) {
    padding: 0 20px;
  }
  &.row {
    padding: 0 20px !important;
  }
}

/* tag
------------------------------------------------------------ */
img {
  // width: auto;
  // height: auto;
  border:0;
  vertical-align:bottom;
  backface-visibility: hidden;
}
// picture {
//  display: inline-block;
//  width: 100%;
//  height: 100%;
// }
div,section,h1,h2,h3,h4,h5,h6,p,ol,ul,li,table,caption,tbody,tfoot,thead,tr,th,td,dl,dt,dd,a,span,img,small,strong,b,figure {
  font-size:100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
figure {
  margin: 0;
}
button {
  padding: 0;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-appearance: none;
          appearance: none;
}

/* link
------------------------------------------------------------ */
a {
  color: #666;
  overflow: hidden;
  outline:none; /* for FF*/
  text-decoration: none;
  @include transition;
}
a:hover, a:active, a:focus {text-decoration: none;}


/* class
------------------------------------------------------------ */
.clearfix:after {content: "";display: block;clear: both;}

@media screen and (max-width: $breakpoint) {
  .pcElement {display: none !important;}
}
@media screen and (min-width: $breakpoint + 1px) {
  .spElement {display: none !important;}
}

/* -----font----- */
.roboto {
  font-family: 'Roboto', sans-serif;
}


/* layout
------------------------------------------------------------ */
.flex {
  display: -webkit-flex;
  display: flex;
  &.inline {
    display: -webkit-inline-flex;
    display: inline-flex;
  }
  &.column {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  &.wrap {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }
  &.itemCenter {
    -webkit-align-items: center;
            align-items: center;
  }
  &.contentCenter {
    -webkit-justify-content: center;
            justify-content: center;
  }
  &.between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
  &.end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }
}


/* animation
------------------------------------------------------------ */
/* fadeIn */
@media screen and (max-width: $breakpoint) {
  .fadeBlock {
    overflow: hidden;
  }
}
.fadeEl {
  opacity: 0;
  @include transition($duration:.8s);
  &.fromTop {
    @include transform-translate($Y: -50px);
  }
  &.fromRight {
    @include transform-translate($X: 50px);
  }
  &.fromBottom {
    @include transform-translate($Y: 50px);
  }
  &.fromLeft {
    @include transform-translate($X: -50px);
    &.rotateY {
      -webkit-transform: translateX(-50px) rotateY(360deg);
              transform: translateX(-50px) rotateY(360deg);
    }
  }
}
.fadeEl.fadein {
  opacity: 1;
  &.fromTop, &.fromRight, &.fromBottom, &.fromLeft {
    @include transform-translate;
  }
  &.fromLeft {
    &.rotateY {
      -webkit-transform: none;
              transform: none;
    }
  }
}

@-webkit-keyframes mask {
  0% {margin-left: 0%;}
  50% {margin-left: 0%;}
  100% {margin-left: 100%;}
}
@keyframes mask {
  0% {margin-left: 0%;}
  50% {margin-left: 0%;}
  100% {margin-left: 100%;}
}
.maskWrap {
  position: fixed;
  z-index: 100000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  .maskA {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #45BC9C;
    -webkit-animation: mask 1.5s ease .35s 1 forwards;
            animation: mask 1.5s ease .35s 1 forwards;
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    -webkit-animation: mask 1.5s ease 0s 1 forwards;
            animation: mask 1.5s ease 0s 1 forwards;
  }
}

.fadeIn {
  animation-name: fadeIn;
}
.fadeInLeft {
  animation-name: fadeInLeft;
}
.fadeInRight {
  animation-name: fadeInRight;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-2000px, 0, 0);
            transform: translate3d(-2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(2000px, 0, 0);
            transform: translate3d(2000px, 0, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}





.objectFitImg1 {
  object-fit: cover;
  font-family: 'object-fit: cover;';
  width: 100%;
  height: 100%;
}
.objectFitImg2 {
  object-fit: contain;
  font-family: 'object-fit: contain;';
  width: 100%;
  height: 100%;
}


/* ------------------------------------------------------------ */
.logoArea {
  .logoWrap {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
  }
}


/* object
------------------------------------------------------------ */
.oBtn {
  @include oBtn(180px, 50px);
  @media screen and (max-width: $breakpoint) {
    @include oBtn(120px,40px);
  }
}

/* utility
------------------------------------------------------------ */
.alignCenter {text-align: center;}
.alignRight {text-align: right;}
.alignLeft {text-align: left;}


/* header
============================================================ */
.mainHeader {
  a,
  a:hover,
   {
    color: #666 !important;
    text-decoration: none !important;
  }
}
@media screen and (max-width: $breakpoint) {
  .navToggle {
    width: 50px;
    height: 50px;
    .spMenuBtn {
      width: 100%;
      height: 100%;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      .bar {
        width: 25px;
        height: 1px;
        background: #898989;
        @include transition;
        + .bar {
          margin-top: 7px;
        }
      }
    }
    &.open {
      .bar1 {
        -webkit-transform: rotate(225deg) translate(-6px, -6px);
                transform: rotate(225deg) translate(-6px, -6px);
      }
      .bar2 {
        -webkit-transform: rotateX(270deg);
                transform: rotateX(270deg);
      }
      .bar3 {
        -webkit-transform: rotate(-225deg) translate(-5px, 5px);
                transform: rotate(-225deg) translate(-5px, 5px);
      }
    }
  }
}

.mainHeader {
  font-family: YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  height: 64px;
  @media screen and (max-width: $breakpoint) {
    height: 50px;
  }
}
.gNavHeader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 64px;
  background: #FFF;
  @media screen and (min-width: $breakpoint + 1px) {
   min-width: 960px;
  }
  @media screen and (max-width: $breakpoint) {
    right: 0;
    height: 50px;
  }
  .inner {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    @media screen and (max-width: $breakpoint) {
      padding-right: 10px;
    }
  }
  .logoArea {
    @media screen and (min-width: $breakpoint + 1px) {
      display: flex;
      -webkit-align-items: flex-end;
              align-items: flex-end;
      height: 64px;
      padding-bottom: 9px;
    }
    .logoWrap {
      &:hover {
        opacity: 0.7;
      }
    }
    .logo {
      width: 134px;
      @media screen and (max-width: $breakpoint) {
        width: 94px;
      }
    }
  }
}
.gNav {
  height: 100%;
  @media screen and (max-width: $breakpoint) {
    position: absolute;
    top: 50px;
    left: 0;
    right: 0;
    width: 100%;
    height: -webkit-calc(100vh - 50px);
    height: calc(100vh - 50px);
    background: #FFF;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    -ms-overflow-y: scroll;
        overflow-y: scroll;
    -webkit-transform: perspective(50vh) rotateX(-90deg);
            transform: perspective(50vh) rotateX(-90deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    @include transition;
    .navToggle.open + & {
      -webkit-transform: perspective(50vh) rotateX(0);
              transform: perspective(50vh) rotateX(0);
    }
  }
  ul {
    @media screen and (min-width: $breakpoint + 1px) {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      // height: 100%;
    }
    @media screen and (max-width: $breakpoint) {
      font-size: 0;
    }
    .navList {
      @media screen and (min-width: $breakpoint + 1px) {
        // height: 100%;
      }
      &.contact {
        .nav {
          @media screen and (max-width: $breakpoint) {
            position: relative;
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            width: 100%;
            height: 40px;
            border: 1px solid #e5e5e5;
            &::before {
              position: absolute;
              top: 50%;
              right: auto;
              left: -webkit-calc(50% + 55px);
              left: calc(50% + 55px);
              width: 18px;
              height: 1px;
              background: $dc;
              content: "";
              @include transition;
            }
            &::after {
              position: absolute;
              top: 50%;
              right: auto;
              left: -webkit-calc(50% + 55px + 10px);
              left: calc(50% + 55px + 10px);
              width: auto;
              height: auto;
              margin-top: -4px;
              border: solid $dc;
              border-width: 2px 4px;
              border-top-color: transparent;
              border-right-color: transparent;
              content: "";
              -webkit-transform: none;
                      transform: none;
              @include transition;
            }
          }
        }
      }
      .nav {
        display: -webkit-flex;
        display: flex;
        height: 64px;
        font-size: 12px;
        @media screen and (min-width: $breakpoint + 1px) {
          -webkit-align-items: flex-end;
                  align-items: flex-end;
          -webkit-justify-content: center;
                  justify-content: center;
          padding: 0 1em 1em;
        }
        @media screen and (max-width: $breakpoint) {
          position: relative;
          -webkit-align-items: center;
                  align-items: center;
          height: 44px;
          padding: 0 20px !important;
          border-bottom: 1px solid #aaa;
          &::after {
            position: absolute;
            top: 50%;
            right: 22px;
            width: 8px;
            height: 8px;
            margin-top: -4px;
            border-right: 1px solid #666;
            border-bottom: 1px solid #666;
            content: "";
            -webkit-transform: rotate(-45deg);
                    transform: rotate(-45deg);
            z-index: 2;
          }
        }
        .txt {
          position: relative;
          text-align: center;
          span {
            display: inline-block;
          }
          &::after {
            @media screen and (min-width: $breakpoint + 1px) {
              position: absolute;
              bottom: -4px;
              left: 0;
              width: 100%;
              height: 2px;
              background: #ccc;
              content: "";
              -webkit-transform: rotateY(90deg);
                      transform: rotateY(90deg);
              @include transition;
            }
          }
        }
        @media screen and (min-width: $breakpoint + 1px) {
          &:hover,
          &.cr {
            .txt {
              &::after {
                -webkit-transform: rotateY(0);
                        transform: rotateY(0);
              }
            }
          }
        }
      }
      &.cv {
        display: -webkit-flex;
        display: flex;
        @media screen and (max-width: $breakpoint) {
          display: inline-flex;
          width: 50%;
        }
        &.lLogin {
          margin-left: 12px;
          @media screen and (max-width: $breakpoint) {
            margin-left: 0 !important;
            padding-left: 20px !important;
          }
        }
        &.lCart {
          @media screen and (max-width: $breakpoint) {
            -webkit-justify-content: flex-end;
                    justify-content: flex-end;
            padding-right: 20px !important;
          }
          .oBtn {
            background: #E5E5E5 !important;
          }
        }
        .oBtn {
          -webkit-align-items: flex-end;
                  align-items: flex-end;
          width: 74px;
          height: 64px;
          padding-bottom: 1em !important;
          background: #FFF !important;
          border: 1px solid #e5e5e5;
          font-size: 12px;
          @media screen and (max-width: $breakpoint) {
            width: -webkit-calc(100% - 7px);
            width: calc(100% - 7px);
            height: 40px;
            margin: 0;
            font-size: 12px;
          }
          &:hover {
            background: #898989;
            color: #fff;
          }
          &::before,
          &::after {
            @media screen and (min-width: $breakpoint + 1px) {
              content: none;
            }
          }
        }
      }
      &.spBtn {
        @media screen and (max-width: $breakpoint) {
          margin-bottom: 10px !important;
          padding: 0 20px !important;
        }
        &.telWrap {
          @media screen and (max-width: $breakpoint) {
            margin-top: 20px !important;
          }
        }
      }
      .tel {
        width: 100% !important;
        height: 40px;
        font-size: 20px;
        font-weight: $bold;
        letter-spacing: 1px;
        img {
          width: 16px;
          margin-right: 5px;
        }
      }
    }
  }
}


/* breadcrumb
============================================================ */
.breadcrumb {
  font-family: YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  padding: 13px 0;
  background: #F3F3F3;
  @media screen and (max-width: $breakpoint) {
    padding: 7px 20px;
  }
  ol {
    font-size: 0;
    @media screen and (min-width: $breakpoint + 1px) {
      width: 960px;
      margin: auto;
    }
  }
  li {
    display: inline;
    a {
      span {
        color: #000;
      }
      + span {
        margin: 0 .5em;
      }
    }
    .cr {
      pointer-events: none;
      span {
        color: #aaa;
      }
    }
    span {
      color: #aaa;
      font-size: 10px;
    }
  }
}


/* pagetop
------------------------------------------------------------ */
.pagetop {
  position: relative;
  display: block;
  .oBtn {
    position: fixed;
    z-index: 10;
    bottom: 78px;
    right: 20px;
    width: 80px;
    height: 80px;
    padding-top: 36px;
    -webkit-border-radius: 50%;
            border-radius: 50%;
    font-size: 12px;
    line-height: 1;
    @media screen and (max-width: $breakpoint) {
      bottom: 35px;
      right: 5px;
      width: 44px;
      height: 44px;
      padding-top: 20px;
      font-size: 10px;
    }
    &:hover {
      &::before {
        top: 6px;
        background: #FFF;
      }
      &::after {
        top: 6px;
        border-bottom-color: #FFF;
        border-right-color: #FFF;
      }
    }
    &::before {
      position: absolute;
      top: 12px;
      left: 50%;
      width: 1px;
      height: 32px;
      background: $dc;
      content: "";
      @include transition;
      @media screen and (max-width: $breakpoint) {
        top: 6px;
        height: 18px;
      }
    }
    &::after {
      position: absolute;
      top: 12px;
      left: 50%;
      margin-left: -4px;
      border: solid $dc;
      border-width: 4px 2px;
      border-top-color: transparent;
      border-left-color: transparent;
      content: "";
      @include transition;
      @media screen and (max-width: $breakpoint) {
        top: 6px;
      }
    }
  }
}

/* footer
============================================================ */
.footerWrap {
  font-family: YuGothic, '游ゴシック', "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  a,
  a:hover {
    color: #666 !important;
    text-decoration: none !important;
  }
}
.footerContent {
  padding-top: 80px;
  border-top: 1px solid #e5e5e5;
  @media screen and (max-width: $breakpoint) {
    padding-top: 40px;
  }
  .inner {
    @media screen and (min-width: $breakpoint + 1px) {
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
    }
  }
  .footerNav {
    @media screen and (min-width: $breakpoint + 1px) {
      display: flex;
    }
  }
  .navBlock {
    @media screen and (min-width: $breakpoint + 1px) {
      width: 320px;
    }
    + .navBlock {
      @media screen and (max-width: $breakpoint) {
        margin-top: 40px;
      }
    }
    a {
      position: relative;
      &:hover {
        &::after {
          width: 100%;
        }
      }
      &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0;
        height: 1px;
        background: $dc;
        content: "";
        @include transition;
      }
    }
    .heading {
      color: #aaa;
      font-size: 15px;
      font-weight: $bold;
      line-height: (22 / 15);
      letter-spacing: 1px;
      line-height: 1;
      @media screen and (max-width: $breakpoint) {
        font-size: 13px;
        line-height: 1;
      }
    }
    .boxWrap {
      margin-top: 15px;
      @media screen and (min-width: $breakpoint + 1px) {
        height: 89%;
        padding-left: 1em;
        border-left: 1px solid #e5e5e5;
      }
      @media screen and (max-width: $breakpoint) {
        display: flex;
        margin-top: 8px;
        padding-top: 20px;
        border-top: 1px solid #e5e5e5;
      }
    }
    .box {
      @media screen and (max-width: $breakpoint) {
        width: 50%;
        + .box {
          border-left: 1px solid #e5e5e5;
          padding-left: 1em;
        }
      }
      li {
        @media screen and (max-width: $breakpoint) {
          margin: 0;
          padding: 0;
          border: none;
        }
      }
      a {
        font-size: 11px;
        line-height: (22 / 11);
        @media screen and (max-width: $breakpoint) {
          margin: 0;
          padding: 0;
          background: none;
          font-size: 10px;
          font-weight: $regular;
          line-height: (60 / 20);
        }
      }
    }
  }
  .footerCv {
    margin-top: 35px;
    .telBox {
      letter-spacing: 1px;
      line-height: 1;
      .tel {
        font-size: 32px;
        font-weight: $bold;
        @media screen and (min-width: $breakpoint + 1px) {
          pointer-events: none;
        }
        @media screen and (max-width: $breakpoint) {
          display: flex;
          -webkit-align-items: center;
                  align-items: center;
          -webkit-justify-content: center;
                  justify-content: center;
          width: 100% !important;
          height: 40px;
          border: 1px solid #e5e5e5;
          font-size: 20px;
        }
        img {
          margin-right: 10px;
          vertical-align: middle;
          @media screen and (max-width: $breakpoint) {
            width: 16px;
            margin-right: 5px;
          }
        }
      }
      .time {
        margin-top: 11px;
        font-size: 11px;
        @media screen and (max-width: $breakpoint) {
          margin-top: 8px;
          font-size: 10px;
        }
      }
    }
    .noteBox {
      margin-top: 30px;
      padding: 37px 35px 32px;
      background: #f3f3f3;
      @media screen and (max-width: $breakpoint) {
        margin-top: 15px;
        padding: 15px 19px;
      }
      .txt {
        padding-bottom: 6px;
        border-bottom: 1px solid #d2d2d2;
        font-size: 12px;
        line-height: 1;
        @media screen and (max-width: $breakpoint) {
          padding-bottom: 11px;
          font-size: 11px;
        }
        b {
          margin-left: 4px;
          font-size: 20px;
          letter-spacing: 2px;
          @media screen and (max-width: $breakpoint) {
            font-size: 19px;
          }
        }
      }
      img {
        @media screen and (max-width: $breakpoint) {
          width: 100%;
        }
      }
    }
  }
  .logoWrap {
    margin: 95px 0 10px;
    @media screen and (max-width: $breakpoint) {
      margin: 30px 0 15px;
    }
    .logo {
      @media screen and (min-width: $breakpoint + 1px) {
        width: 140px;
      }
      @media screen and (max-width: $breakpoint) {
        width: 87px;
      }
    }
  }
}

.copyright {
  height: 68px;
  padding-top: 10px;
  border-top: 1px solid #e5e5e5;
  color: $dc;
  @media screen and (max-width: $breakpoint) {
    height: 29px;
    padding-top: 5px;
  }
  small {
    font-size: 10px;
    letter-spacing: 0.5px;
  }
}



/* top
============================================================ */
.top {
  .mainvisual {
    position: relative;
    height: 780px;
    @media screen and (max-width: $breakpoint) {
      height: 402px;
    }
    .heading {
      position: absolute;
      top: 110px;
      width: 100%;
      @media screen and (max-width: $breakpoint) {
        top: 30px;
      }
      img {
        @media screen and (max-width: $breakpoint) {
          width: 40px;
        }
      }
    }
    .scroll {
      position: absolute;
      bottom: 48px;
      left: 50%;
      font-size: 12px;
      line-height: 1;
      letter-spacing: 2px;
      overflow: visible;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%);
      @media screen and (max-width: $breakpoint) {
        bottom: 25px;
        font-size: 10px;
      }
      &::after {
        position: absolute;
        bottom: -48px;
        left: 50%;
        width: 1px;
        height: 40px;
        background: $dc;
        content: "";
        @media screen and (max-width: $breakpoint) {
          bottom: -25px;
          height: 20px;
        }
      }
    }
  }
  .block {
    padding-top: 100px;
    background: url(../img/top/bg1.png) no-repeat;
    background-position: center top;
    -webkit-background-size: contain;
            background-size: contain;
    @media screen and (max-width: $breakpoint) {
      padding: 40px 0 40px;
    }
  }
  .figure {
    img {
      @media screen and (max-width: $breakpoint) {
        width: 100%;
      }
    }
  }
  .block1 {
    padding: 140px 0;
    @media screen and (max-width: $breakpoint) {
      padding: 40px 0;
    }
    .row {
      margin-top: -19px;
    }
    .box {
      @media screen and (min-width: $breakpoint + 1px) {
        width: -webkit-calc((100% - 8px * 3) / 4);
        width: calc((100% - 8px * 3) / 4);
      }
      @media screen and (max-width: $breakpoint) {
        width: -webkit-calc((100% - 16px) / 2);
        width: calc((100% - 16px) / 2);
        margin-top: 19px;
      }
      &:hover {
        img {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
      }
      .imgWrap {
        overflow: hidden;
        img {
          width: 100%;
          @include transition;
        }
      }
      .txt {
        margin-top: 20px;
        font-size: 13px;
        line-height: 1;
        @media screen and (max-width: $breakpoint) {
          margin-top: 8px;
          font-size: 10px;
        }
      }
    }
  }
  .block2 {
    .heading1 {
      margin-bottom: 106px;
      @media screen and (max-width: $breakpoint) {
        margin-bottom: 50px;
      }
    }
    .lSecBox1 {
      @media screen and (max-width: $breakpoint) {
        margin-top: -32px;
      }
    }
  }
  .block3 {
    .figure {
      @media screen and (min-width: $breakpoint + 1px) {
        -webkit-order: 2;
                order: 2;
      }
    }
    .lSecBox1 {
      @media screen and (min-width: $breakpoint + 1px) {
        -webkit-order: 1;
                order: 1;
      }
      @media screen and (max-width: $breakpoint) {
        margin-top: -10px;
      }
    }
  }
  .block4 {
    .lSecBox1 {
      @media screen and (max-width: $breakpoint) {
        margin-top: -17px;
      }
    }
  }
}

.heading1 {
  font-size: 20px;
  font-weight: $bold;
  letter-spacing: 5px;
  line-height: 1;
  text-align: center;
  @media screen and (max-width: $breakpoint) {
    font-size: 15px;
  }
  .sub {
    display: block;
    margin-top: 10px;
    font-size: 10px;
    letter-spacing: 5px;
  }
}
.lRow1 {
  position: static;
  min-height: auto;
  padding: 0;
  background: none;
  border: none;
  text-align: initial;
  @media screen and (min-width: $breakpoint + 1px) {
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }
}
.lSecBox1 {
  @media screen and (min-width: $breakpoint + 1px) {
    width: 380px;
  }
  .ttl {
    font-size: 48px;
    font-weight: $light;
    line-height: 1;
    letter-spacing: 1px;
    @media screen and (max-width: $breakpoint) {
      font-size: 30px;
    }
    .copy {
      color: #aaa;
      font-size: 15px;
      font-weight: $bold;
      letter-spacing: 3px;
      @media screen and (max-width: $breakpoint) {
        display: block;
        font-size: 12px;
      }
    }
    .name {
      line-height: (78.19 / 48);
      @media screen and (max-width: $breakpoint) {
        display: block;
        margin: 20px 0 12px;
        line-height: 1;
      }
    }
  }
  .txt {
    font-size: 13px;
    line-height: (22 / 13);
    @media screen and (max-width: $breakpoint) {
      font-size: 12px;
      line-height: (40.8 / 24);
    }
  }
  .oBtn {
    margin: 2em 0 0;
    @media screen and (max-width: $breakpoint) {
      margin: 2em auto 0px;
    }
  }
}


/* Page
============================================================ */
.mainvisual1 {
  position: relative;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 180px;
  text-align: center;
  @media screen and (max-width: $breakpoint) {
    height: 90px;
  }
  .heading {
    position: relative;
    font-size: 28px;
    font-weight: normal;
    letter-spacing: 2px;
    @media screen and (max-width: $breakpoint) {
      font-size: 16px;
    }
  }
  .objectFitImg1 {
    position: absolute;
  }
}
.mainvisual2 {
  position: relative;
  @media screen and (min-width: $breakpoint + 1px) {
    display: flex;
    -webkit-align-items: center;
            align-items: center;
    height: 500px;
  }
  .imgWrap {
    display: block;
    @media screen and (min-width: $breakpoint + 1px) {
      position: absolute;
      height: 100%;
    }
  }
  .box {
    position: relative;
    text-align: center;
    @media screen and (min-width: $breakpoint + 1px) {
      width: 430px;
      padding: 0 24px;
    }
    @media screen and (max-width: $breakpoint) {
      margin-top: 14px;
    }
    .txt {
      color: #aaa;
      font-size: 14px;
      line-height: 2;
      letter-spacing: 2px;
      text-align: center;
      @media screen and (max-width: $breakpoint) {
        font-size: 10px;
      }
    }
  }
  .heading {
    float: none;
    position: relative;
    margin-bottom: 14px;
    padding-bottom: 20px;
    font-size: 24px;
    letter-spacing: 2px;
    @media screen and (max-width: $breakpoint) {
      margin-bottom: 5px;
      padding-bottom: 5px;
      font-size: 15px;
    }
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: #f7f7f7;
      background: -moz-linear-gradient(left, #f7f7f7 0%, #aaaaaa 50%, #f7f7f7 100%);
      background: -webkit-linear-gradient(left, #f7f7f7 0%,#aaaaaa 50%,#f7f7f7 100%);
      background: linear-gradient(to right, #f7f7f7 0%,#aaaaaa 50%,#f7f7f7 100%);
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f7f7f7', endColorstr='#f7f7f7',GradientType=1 );
      content: "";
      @media screen and (max-width: $breakpoint) {
        width: 191px;
        left: -webkit-calc(50% - (191px / 2));
        left: calc(50% - (191px / 2));
      }
    }
    .small {
      color: #aaa;
      font-size: 16px;
      letter-spacing: 1px;
      @media screen and (max-width: $breakpoint) {
        font-size: 10px;
      }
    }
  }
}
.lSection1 {
  padding: 5.7857em 0 6.857em;
  @media screen and (max-width: $breakpoint) {
    padding: 40px 20px;
  }
  .ttl {
    margin-bottom: 4px;
    color: #aaa;
    font-size: 22px;
    font-weight: normal;
    line-height: (60 / 22);
    letter-spacing: 2px;
    text-align: center;
    @media screen and (max-width: $breakpoint) {
      margin-bottom: 18px;
      font-size: 14px;
      line-height: (60 / 28);
    }
  }
  .txt {
    font-size: 13px;
    line-height: (22 / 13);
    @media screen and (min-width: $breakpoint + 1px) {
      text-align: center;
    }
    @media screen and (max-width: $breakpoint) {
      font-size: 12px;
      line-height: (40.8 / 24);
    }
  }
}
.lSection2 {
  padding-top: 100px;
  background: url(../img/top/bg1.png) no-repeat;
  background-position: center top;
  -webkit-background-size: contain;
          background-size: contain;
  @media screen and (max-width: $breakpoint) {
    padding: 40px 20px;
  }
  .ttl {
    font-size: 24px;
    font-weight: normal;
    letter-spacing: 2px;
    text-align: center;
    @media screen and (max-width: $breakpoint) {
      font-size: 14px;
    }
  }
}
.heading2 {
  margin: 23px 0 54px;
  color: #aaa;
  font-size: 20px;
  font-weight: $bold;
  letter-spacing: 5px;
  line-height: (60 / 20);
  text-align: center;
  @media screen and (max-width: $breakpoint) {
    margin: 43px 0 32px;
    font-size: 15px;
    line-height: (60 / 30);
    letter-spacing: 4px;
  }
  .ttl + .section & {
    @media screen and (max-width: $breakpoint) {
      margin-top: 17px;
    }
  }
}
.lRow2 {
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.lBox1 {
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  @media screen and (min-width: $breakpoint + 1px) {
    -webkit-flex-direction: column;
            flex-direction: column;
    height: 415px;
  }
  @media screen and (max-width: $breakpoint) {
    margin-bottom: 25px;
  }
  .lRow2.three & {
    @media screen and (min-width: $breakpoint + 1px) {
      width: -webkit-calc(100% / 3);
      width: calc(100% / 3);
    }
  }
  .img {
    width: 250px;
    @media screen and (max-width: $breakpoint) {
      width: 100px;
      margin: 0 25px 0 15px;
    }
    img {
      width: 100%;
    }
  }
  .txt {
    @media screen and (min-width: $breakpoint + 1px) {
      margin-top: 17px;
      text-align: center;
    }
  }
  .name {
    font-size: 18px;
    font-weight: $bold;
    line-height: (22 / 18);
    @media screen and (min-width: $breakpoint + 1px) {
      text-align: center;
    }
    @media screen and (max-width: $breakpoint) {
      font-size: 12px;
      line-height: (33.6 / 24);
    }
  }
  .place {
    color: #aaa;
    font-size: 12px;
    line-height: (30 / 12);
    @media screen and (min-width: $breakpoint + 1px) {
      text-align: center;
    }
    @media screen and (max-width: $breakpoint) {
      font-size: 10px;
      line-height: (34 / 20);
    }
  }
}

/* agents
------------------------------------------------------------ */
#agents {
  .block1 {
    position: relative;
    @media screen and (min-width: $breakpoint + 1px) {
      height: 547px;
    }
    .lSection1 {
      .ttl {
        margin-bottom: 20px;
        color: #626262;
        @media screen and (max-width: $breakpoint) {
          margin-bottom: 0;
        }
      }
      .img {
        @media screen and (min-width: $breakpoint + 1px) {
          position: absolute;
          top: 0;
          left: -webkit-calc(50% - 542px);
          left: calc(50% - 542px);
        }
        img {
          @media screen and (min-width: $breakpoint + 1px) {
            height: 547px;
          }
          @media screen and (max-width: $breakpoint) {
            width: 100%;
          }
        }
      }
      ul {
        @media screen and (min-width: $breakpoint + 1px) {
          width: 513px;
          margin-left: auto;
        }
        li {
          font-size: 16px;
          line-height: 1;
          @media screen and (max-width: $breakpoint) {
            padding-left: 24px;
            text-indent: -24px;
            font-size: 10px;
          }
          + li {
            margin-top: 36px;
            @media screen and (max-width: $breakpoint) {
              margin-top: 25px;
            }
          }
          img {
            margin-right: 20px;
            vertical-align: middle;
            @media screen and (max-width: $breakpoint) {
              width: 14px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
  .block2 {
    .lSection2 {
      padding-bottom: 94px;
      @media screen and (max-width: $breakpoint) {
        padding-bottom: 46px;
      }
      > .txt {
        margin: 18px 0 21px;
        color: #aaa;
        font-size: 13px;
        @media screen and (max-width: $breakpoint) {
          margin: 11px 0 27px;
          font-size: 10px;
          line-height: 2;
        }
      }
      .img {
        img {
          @media screen and (max-width: $breakpoint) {
            width: 100%;
          }
        }
      }
      .section {
        .ttl {
          margin-bottom: 18px;
          color: #aaa;
          font-size: 15px;
          font-weight: $bold;
          line-height: (27.2 / 15);
          letter-spacing: 3px;
          @media screen and (max-width: $breakpoint) {
            margin: 19px 0 14px;
            font-size: 12px;
            line-height: 2;
            letter-spacing: 0;
            white-space: nowrap;
          }
        }
        .txt {
          font-size: 13px;
          line-height: 2;
          @media screen and (min-width: $breakpoint + 1px) {
            padding: 0 60px;
          }
          @media screen and (max-width: $breakpoint) {
            font-size: 12px;
            line-height: (40.8 / 24);
          }
        }
      }
    }
  }
  .block3,
  .block4 {
    .lRow1 {
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center;
      padding-bottom: 100px;
      @media screen and (max-width: $breakpoint) {
        padding-bottom: 46px;
      }
    }
    .figure {
      img {
        @media screen and (max-width: $breakpoint) {
          width: 100%;
        }
      }
    }
    .lSecBox1 {
      @media screen and (min-width: $breakpoint + 1px) {
        width: 405px;
      }
      .ttl {
        margin-bottom: 24px;
        @media screen and (min-width: $breakpoint + 1px) {
          text-align: left;
        }
        @media screen and (max-width: $breakpoint) {
          margin-bottom: 20px;
        }
        .copy {
          font-size: 17px;
          @media screen and (max-width: $breakpoint) {
            font-size: 12px;
          }
        }
      }
      .txt {
        line-height: (26 / 13);
        @media screen and (max-width: $breakpoint) {
          font-size: 12px;
          line-height: (40.8 / 24);
        }
      }
    }
  }
  .block3 {
    .figure {
      @media screen and (max-width: $breakpoint) {
        padding: 0 25px;
      }
    }
    .lSecBox1 {
      @media screen and (min-width: $breakpoint + 1px) {
        margin-left: 40px;
      }
      .ttl {
        @media screen and (max-width: $breakpoint) {
          margin-top: 13px;
        }
      }
    }
  }
  .block4 {
    .figure {
      -webkit-order: 2;
              order: 2;
      @media screen and (min-width: $breakpoint + 1px) {
        margin-left: 40px;
      }
      @media screen and (max-width: $breakpoint) {
        padding: 0 40px;
      }
    }
    .lSecBox1 {
      -webkit-order: 1;
              order: 1;
      .ttl {
        @media screen and (max-width: $breakpoint) {
          margin-top: 20px;
        }
      }
    }
  }
  .block5 {
    .lSection2 {
      @media screen and (max-width: $breakpoint) {
        padding-bottom: 25px;
      }
      .ttl {
        margin-bottom: 32px;
        @media screen and (max-width: $breakpoint) {
          margin-bottom: 12px;
          line-height: 2;
        }
      }
      > .img {
        img {
          @media screen and (max-width: $breakpoint) {
            width: 100%;
          }
        }
      }
      > .txt {
        margin-top: 32px;
        font-size: 13px;
        line-height: (26 / 13);
        @media screen and (min-width: $breakpoint + 1px) {
          text-align: center;
        }
        @media screen and (max-width: $breakpoint) {
          margin-top: 15px;
          font-size: 12px;
          line-height: (40.8 / 24);
        }
      }
      .lRow2 {
        margin-top: 35px;
        @media screen and (max-width: $breakpoint) {
          margin-top: 25px;
        }
      }
    }
  }
  .block6 {
    .lSection2 {
      .ttl {
        margin-bottom: 32px;
        @media screen and (max-width: $breakpoint) {
          margin-bottom: 15px;
        }
      }
      .img {
        @media screen and (max-width: $breakpoint) {
          padding: 0 40px;
        }
        img {
          @media screen and (max-width: $breakpoint) {
            width: 100%;
          }
        }
      }
    }
    ul {
      margin: 30px 0 100px;
      padding: 60px;
      border: 1px solid #e7e7e7;
      @media screen and (max-width: $breakpoint) {
        margin: 20px 0 10px;
        padding: 18px;
      }
      li {
        font-size: 13px;
        line-height: 1;
        @media screen and (max-width: $breakpoint) {
          padding-left: 1em;
          text-indent: -1em;
          font-size: 12px;
          line-height: (40.8 / 24);
        }
        + li {
          margin-top: 30px;
          @media screen and (max-width: $breakpoint) {
            margin-top: 1em;
          }
        }
      }
    }
  }
  .bottomCvBlock {
    padding: 100px 0;
    background: url(../img/Page/agents/img7.jpg) no-repeat;
    background-position: center;
    -webkit-background-size: cover;
            background-size: cover;
    @media screen and (max-width: $breakpoint) {
      padding: 43px 0 50px;
    }
    .ttl {
      margin-bottom: 32px;
      font-size: 24px;
      line-height: (38 / 24);
      letter-spacing: 2px;
      text-align: center;
      @media screen and (max-width: $breakpoint) {
        margin-bottom: 23px;
        font-size: 14px;
        line-height: 2;
      }
    }
    .row {
      @media screen and (min-width: $breakpoint + 1px) {
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
      }
      @media screen and (max-width: $breakpoint) {
        position: static;
        min-height: auto;
        padding: 0;
        background: none;
        border: none;
        text-align: center;
      }
      .box {
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-between;
                justify-content: space-between;
        padding: 40px 0 36px;
        background: #fff;
        @media screen and (min-width: $breakpoint + 1px) {
          width: 345px;
          height: 174px;
        }
        @media screen and (max-width: $breakpoint) {
          padding: 25px 20px;
        }
        + .box {
          @media screen and (min-width: $breakpoint + 1px) {
            margin-left: 40px;
          }
          @media screen and (max-width: $breakpoint) {
            margin-top: 15px;
          }
        }
        .ttl {
          margin-bottom: 0;
          color: #aaa;
          font-size: 15px;
          font-weight: $bold;
          line-height: 1;
          letter-spacing: 1px;
          @media screen and (max-width: $breakpoint) {
            margin-bottom: 15px;
            font-size: 12px;
          }
        }
        .tel {
          margin-top: 10px;
          font-size: 32px;
          font-weight: $bold;
          line-height: 1;
          letter-spacing: 1px;
          @media screen and (min-width: $breakpoint + 1px) {
            pointer-events: none;
          }
          @media screen and (max-width: $breakpoint) {
            display: flex;
            -webkit-align-items: center;
                    align-items: center;
            -webkit-justify-content: center;
                    justify-content: center;
            width: 240px !important;
            height: 40px;
            margin: 0 auto 10px !important;
            border: 1px solid #e5e5e5;
            font-size: 20px;
          }
          img {
            width: 17px;
            margin-right: 10px;
            vertical-align: middle;
            @media screen and (max-width: $breakpoint) {
              width: 12px;
              margin-right: 5px;
            }
          }
        }
        .time {
          font-size: 11px;
          line-height: 1;
          letter-spacing: 1px;
          @media screen and (max-width: $breakpoint) {
            font-size: 10px;
          }
        }
        .oBtn {
          width: 265px;
          height: 50px;
          margin: 0;
          font-size: 14px;
          @media screen and (max-width: $breakpoint) {
            width: 240px;
            height: 40px;
            font-size: 12px;
          }
        }
      }
    }
  }
}