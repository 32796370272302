@charset "utf-8";

$breakpoint: 768px;

$thin: 100;
$light: 300;
$regular: 400;
$medium: 500;
$bold: 700;

$dc :#626262;

@mixin tri1($value, $bg) {
  border-color: transparent;
  border-top-color: $bg;
  border: {
    style: solid;
    width: $value;
    left-width: $value / 2;
    right-width: $value / 2;
  }
  content: "";
}
@mixin arrDown1($top: 50%, $right: 20px, $width: 8px, $border-color: #fff) {
  position: absolute;
  top: $top;
  right: $right;
  width: $width;
  height: $width;
  margin-top: -$width + 2px;
  border: {
    color: $border-color;
    right: 1px solid;
    bottom: 1px solid;
  }
  content: "";
  @include transform-rotate(45deg);
  z-index: 2;
}
@mixin arrRight1($top: 50%, $right: 13px, $width: 8px, $border-color: #fff) {
  position: absolute;
  top: $top;
  right: $right;
  width: $width;
  height: $width;
  margin-top: -$width / 2;
  border: {
    color: $border-color;
    right: 1px solid;
    bottom: 1px solid;
  }
  content: "";
  @include transform-rotate(-45deg);
  z-index: 2;
  @media screen and (max-width: $breakpoint) {
    width: $width - 2px;
    height: $width - 2px;
    margin-top: -($width - 2px) / 2;
  }
}
@mixin arrRight-border($top: 50%, $right: 13px, $width: 17px, $height: 1px) {
  position: absolute;
  top: $top;
  right: $right;
  width: $width;
  height: $height;
  content: "";
  @include transition;
  @include transform-scaleX;
}
@mixin arrLeft1($top: 50%, $left: 13px, $width: 8px, $border-color: #fff) {
  position: absolute;
  top: $top;
  left: $left;
  width: $width;
  height: $width;
  margin-top: -$width / 2;
  border: {
    color: $border-color;
    left: 1px solid;
    bottom: 1px solid;
  }
  content: "";
  @include transform-rotate(45deg);
  z-index: 2;
  @media screen and (max-width: $breakpoint) {
    width: $width - 2px;
    height: $width - 2px;
    margin-top: -($width - 2px) / 2;
  }
}
@mixin transform-translate($X: 0, $Y: 0) {
  -webkit-transform: translate($X, $Y);
          transform: translate($X, $Y);
}
@mixin transform-scale($value: 0) {
  -webkit-transform: scale($value);
          transform: scale($value);
}
@mixin transform-scaleX($value: 0) {
  -webkit-transform: scaleX($value);
          transform: scaleX($value);
  @include transform-origin-left;
}
@mixin transform-scaleX2($value: 0) {
  -webkit-transform: scaleX($value);
          transform: scaleX($value);
  @include transform-origin-right;
}
@mixin transform-origin-left {
  -webkit-transform-origin: left;
          transform-origin: left;
}
@mixin transform-origin-right {
  -webkit-transform-origin: right;
          transform-origin: right;
}
@mixin transform-rotate($value) {
  -webkit-transform: rotate($value);
          transform: rotate($value);
}
@mixin transform-rotateY($value) {
  -webkit-transform: rotateY($value);
          transform: rotateY($value);
}
@mixin transition($property: all, $duration: 0.3s, $timing: ease-in-out) {
  -webkit-transition: $property $duration $timing;
          transition: $property $duration $timing;
}
@mixin oBtn($width, $height) {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width: $width;
  height: $height;
  margin: auto;
  border: 1px solid;
  font-size: 11px;
  @media screen and (max-width: $breakpoint) {
    font-size: 10px;
  }
  &.normal {
    background-color: #fff;
    border-color: #e5e5e5;
    &.arrR {
      &:hover {
        &::before {
          right: 5px;
          background: #FFF;
        }
        &::after {
          right: 5px;
          border-bottom-color: #FFF;
          border-left-color: #FFF;
        }
      }
    }
    &:hover {
      background-color: $dc;
      color: #FFF;
    }
  }
  &.arrR {
    &::before {
      position: absolute;
      top: 50%;
      right: 11px;
      width: 32px;
      height: 1px;
      background: $dc;
      content: "";
      @include transition;
      @media screen and (max-width: $breakpoint) {
        right: 7px;
        width: 18px;
      }
    }
    &::after {
      position: absolute;
      top: 50%;
      right: 11px;
      margin-top: -4px;
      border: solid $dc;
      border-width: 2px 4px;
      border-top-color: transparent;
      border-right-color: transparent;
      content: "";
      @include transition;
      @media screen and (max-width: $breakpoint) {
        right: 7px;
      }
    }
  }
}
